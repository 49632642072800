/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_ROSTER_KEY = 'SET_ROSTER_KEY';
export const SET_ROSTER_JSON = 'SET_ROSTER_JSON';
export const SET_ROSTER_TYPE = 'SET_ROSTER_TYPE';
